(function (window) {
  /** @type {Array} Console methods */
  const methods = [
    'assert',
    'clear',
    'count',
    'countReset',
    'debug',
    'error',
    'info',
    'log',
    'table',
    'trace',
    'warn',
    'dir',
    'dirxml',
    'group',
    'groupCollapsed',
    'groupEnd',
    'time',
    'timeLog',
    'timeEnd',
    'exception',
    'timeStamp',
    'profile',
    'profileEnd',
  ];

  /** @type {Function} A no-effect function */
  const noop = function () {};

  /**
   * Small debug helper
   *
   * @param {Boolean} hasDebug Do we debug or not?
   */
  function Debugger(hasDebug, prefix) {
    // Reduce all methods to a debug object
    return methods.reduce(function reduceMethods(debug, method) {
      const methodExists = window.console && typeof window.console[method] === 'function';

      // If debug is active and the method exists in the console,
      // bind the method to the window console, else bind the noop function
      if (hasDebug && methodExists) {
        // Add prefix if it exists
        debug[method] = prefix
          ? window.console[method].bind(window.console, prefix)
          : window.console[method].bind(window.console);
      } else {
        debug[method] = noop;
      }

      return debug;
    }, {});
  }

  // Expose it globally
  window.Debugger = Debugger;
})(window);
